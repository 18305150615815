module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Global Math Mind","short_name":"Mmind","start_url":"/","display":"standalone","icon":"src/images/favicon.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0025a202df5163bb4866abc5e713b3ba"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","ja","zh"],"defaultLanguage":"en","path":"/Users/ikenaga/git/mmind/locales","redirect":false,"i18nextOptions":{"debug":false,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false,"react":{"useSuspense":false,"wait":true}},"pages":[{"matchPath":"/lang=:lang/","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
