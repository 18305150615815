// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-modal-js": () => import("./../src/pages/menu_modal.js" /* webpackChunkName: "component---src-pages-menu-modal-js" */),
  "component---src-pages-mmind-answers-js": () => import("./../src/pages/mmind/answers.js" /* webpackChunkName: "component---src-pages-mmind-answers-js" */),
  "component---src-pages-mmind-asite-js": () => import("./../src/pages/mmind/asite.js" /* webpackChunkName: "component---src-pages-mmind-asite-js" */),
  "component---src-pages-mmind-fthinking-js": () => import("./../src/pages/mmind/fthinking.js" /* webpackChunkName: "component---src-pages-mmind-fthinking-js" */),
  "component---src-pages-mmind-index-js": () => import("./../src/pages/mmind/index.js" /* webpackChunkName: "component---src-pages-mmind-index-js" */),
  "component---src-pages-mmind-quiz-js": () => import("./../src/pages/mmind/quiz.js" /* webpackChunkName: "component---src-pages-mmind-quiz-js" */),
  "component---src-pages-mmind-result-js": () => import("./../src/pages/mmind/result.js" /* webpackChunkName: "component---src-pages-mmind-result-js" */)
}

