import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";
import { setCurrentLanguageByUrl, setupFacebookSdk } from "./static/common.js";

import $ from "jquery";

import { quiz_main } from "./static/quiz.js";
import { fthinking_main } from "./static/fthinking.js";

// Indigo comment: copied from app/assets/javascripts/mmind/mmind.js
export const onPreRouteUpdate = () => {
  $(document).ready(function () {
    if (document.URL.startsWith("https://www.global-math.com/mmind")) {
      window.location.href = "https://mmind.sonyged.com";
    } else if (document.URL.startsWith("https://ja.global-math.com/mmind")) {
      window.location.href = "https://mmind.sonyged.com/ja";
    } else if (document.URL.startsWith("https://zh.global-math.com/mmind")) {
      window.location.href = "https://mmind.sonyged.com/zh";
    } else if (document.URL.startsWith("https://www.global-math.com/")) {
      window.location.href = "https://www.sonyged.com/global-math/";
    } else if (document.URL.startsWith("https://ja.global-math.com/")) {
      window.location.href = "https://www.sonyged.com/ja/global-math/";
    } else if (document.URL.startsWith("https://zh.global-math.com/")) {
      window.location.href = "https://www.sonyged.com/zh/global-math/";
    }


    setupFacebookSdk();
    if (window.location.pathname.includes("/mmind/quiz")) {
      quiz_main();
    } else if (window.location.pathname.includes("/mmind/fthinking")) {
      fthinking_main();
    }
  });
};
